.chatcontainer {
    display: grid;
    grid-template-rows: 10% 78% 12%;
    gap: 0.1rem;
    overflow: hidden;
    margin: 0;
}

.back-btn ion-icon{
    font-size: 50px;
    display: none;
}
.chatcontainer .chat-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    height: 5rem;
    background-color: #3e0d77;
    position: relative;
    z-index: 10;
}
.chatcontainer .user-details {
    display: flex;
    align-items: center;
    color: white;  
}
.chatcontainer .user-details .chatcontainer-avatar img{
    height: 3rem;
    padding: 0 1rem;
}
.chat-messages{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap : 1rem;
    overflow: auto;
}
 ::-webkit-scrollbar{
    width: 0.2rem;
}
::-webkit-scrollbar-thumb {
    background: #ffffff39;
    border-radius: 10px;
}  
.chat-messages .message{
    display: flex;
    align-items: center;
    
}
.chat-messages .message .content{
    max-width: 40%;
    overflow-wrap: break-word;
    padding: 0 1rem ;
    font-size: 1.1rem;
    border-radius: 1rem ;
    color: #d1d1d1;
    background-color: #4f04ff21;
}
.chat-messages .sended{
    justify-content: flex-end;
    
}
.chat-messages .recieved{
    justify-content: flex-start;
    
}
@media screen and (min-width: 720px) and (max-width: 1000px) {
    .chatcontainer {
      grid-template-rows: 15% 70% 15%;
    }
  }
