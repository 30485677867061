.chat-section{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgb(40, 15, 65);
  
}

.container {
  height: 85vh;
  width: 85vw;
  background-color: rgb(25, 5, 43);
  display: grid;
  grid-template-columns: 25% 75%;
  overflow: auto;
  box-shadow: 0 0 5px black;
}
@media screen and (min-width: 720px) and (max-width: 1000px) {
  .container {
    grid-template-columns: 35% 65%;
  }
}
@media screen and (max-width: 720px) {
  .container {
    grid-template-columns:50% 50%;
  }
}
