
.welcome-section {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

.welcome-section .welcome-logo {
    height: 20rem;
}

.welcome-section h1 span{ 
    color: blue;
}
.welcome-section h1,.welcome-section h3{ 
    margin: 0;
}
